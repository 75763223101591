<template>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <LoadingSpinner v-if="!quotes && !quotesByVendor" />
    <main
        v-show="(quotes && quotes.length) || (quotesByVendor && quotesByVendor.length)"
        @click="eventDeligate"
        class="mx-32 my-10 min-h-screen"
    >
        <section
            v-show="user && user.data.position != 'Vendor'"
            class="flex items-center justify-between px-7 py-4 bg-white rounded shadow"
        >
            <h1 class="text-xl font-bold">Request For Quote</h1>
            <div class="flex flex-row-reverse items-start text-sm">
                <router-link
                    :to="{ name: 'newQuote' }"
                    class="px-3.5 py-2 bg-primaryColor rounded text-white capitalize"
                    >Create New Request</router-link
                >
                <div class="mr-16 relative">
                    <div
                        @click="getAllDepartments"
                        data-filterdepartment="department-filter"
                        class="flex items-center border border-borderColor rounded px-3.5 py-2"
                    >
                        <span data-filterdepartment="department-filter">Sort by:</span>
                        <span data-filterdepartment="department-filter" class="w-12 uppercase px-1"
                            >All</span
                        >
                        <img
                            data-filterdepartment="department-filter"
                            src="@/assets/images/chevron-down-icon-grey.svg"
                        />
                    </div>
                    <ul
                        v-if="showDepartments"
                        class="absolute top-full bg-white shadow-md w-full py-2"
                    >
                        <li
                            @click="getAllExpenditureRequests()"
                            class="px-2 py-1 hover:bg-borderColor cursor-pointer"
                        >
                            All
                        </li>
                        <li
                            @click="getExpenditureByDepartment(data.department)"
                            v-for="(data, index) in departments"
                            :key="index"
                            class="px-2 py-1 hover:bg-borderColor cursor-pointer"
                        >
                            {{ data.department }}
                        </li>
                    </ul>
                </div>
                <div class="flex items-center mr-4 w-48 border border-borderColor rounded text-xs">
                    <datePicker v-model="startDate" class="rounded cursor-pointer" />
                    <span class="mx-1 text-fontColor">-</span>
                    <datePicker v-model="endDate" class="rounded cursor-pointer" />
                </div>
                <div class="mr-4">
                    <input
                        type="text"
                        placeholder="Search..."
                        class="w-56 border border-borderColor rounded p-2"
                    />
                </div>
            </div>
        </section>
        <section
            v-if="user && user.data.position != 'Vendor'"
            class="my-6 px-7 py-4 bg-white rounded shadow"
        >
            <div
                class="expenditure-items-wrapper flex items-center justify-between uppercase text-xs text-alternativeBgColor px-6 tracking-widest font-bold"
            >
                <div style="width: 20%" class="flex items-center px-2">
                    <input type="checkbox" class="mr-2 align-text-bottom" />title
                </div>
                <div class="text-center">EXPENDITURE COUNT</div>
                <div class="text-center">VENDORS COUNT</div>
                <div class="px-2">date</div>
                <div class="px-2">status</div>
                <img src="@/assets/images/eye-icon.svg" alt="eye icon" style="visibility: hidden" />
            </div>
            <div
                v-for="quote in quotes"
                :key="quote.id"
                class="expenditure-items-wrapper flex items-center justify-between capitalize my-2.5 py-4 px-6 border border-borderColor rounded text-sm"
            >
                <div style="width: 20%" class="flex items-center px-2">
                    <input type="checkbox" class="mr-2 align-text-bottom" />{{ quote.project_name }}
                </div>
                <div class="text-center">{{ quote.expenditure_request_count }}</div>
                <div class="text-center">{{ quote.vendor_count }}</div>
                <div class="px-2">{{ moment(quote.project_deadline).format('D MMM, YYYY') }}</div>
                <div>
                    <div
                        v-if="
                            quote.status.toLowerCase() === 'initiated' ||
                                quote.status.toLowerCase() === 'pending'
                        "
                        class="w-24 text-center uppercase font-bold py-1 px-4 rounded-full text-white text-xs bg-pillPendingStatus"
                    >
                        {{ quote.status }}
                    </div>
                    <div
                        v-if="quote.status.includes('approved')"
                        class="w-24 text-center uppercase font-bold py-1 px-4 rounded-full text-white text-xs bg-pillApprovedStatus"
                    >
                        {{ quote.status }}
                    </div>
                </div>
                <router-link :to="{ name: 'quoteView', params: { quoteId: quote.id } }">
                    <img
                        src="@/assets/images/eye-icon.svg"
                        alt="eye icon"
                        class="cursor-pointer w-6"
                    />
                </router-link>
            </div>
        </section>
        <!-- vendors only view -->
        <section v-if="user && user.data.position === 'Vendor'">
            <h1 class="font-bold text-2xl mb-6">Request for Quote</h1>
            <div class="grid grid-cols-3 gap-8">
                <div
                    v-for="quote in quotesByVendor"
                    :key="quote.id"
                    class="px-8 py-7 bg-white rounded shadow text-sm"
                >
                    <h1 class="text-lg font-semibold capitalize">{{ quote.project_name }}</h1>
                    <div class="mt-5 mb-3">
                        <span class="inline-block w-32 mr-5">Project Deadline:</span>
                        <span>{{ moment(quote.project_deadline).format('D MMM, YYYY') }}</span>
                    </div>
                    <div class="my-3">
                        <span class="inline-block w-32 mr-5">Project Status:</span>
                        <span
                            class="py-1 px-3 bg-pillPendingStatus text-white uppercase text-xs rounded-full"
                            >{{ quote.status }}</span
                        >
                    </div>
                    <div class="my-3">
                        <span class="inline-block w-32 mr-5">Date Created:</span>
                        <span>{{ moment(quote.created_at).format('D MMM, YYYY') }}</span>
                    </div>
                    <hr class="my-4" />
                    <div class="my-1 flex justify-between items-center text-xs">
                        <router-link
                            :to="{ name: 'vendorQuoteView', params: { quoteId: quote.id } }"
                            class="text-white font-semibold rounded bg-primaryColor py-2 px-4 capitalize"
                            >View Request</router-link
                        >
                        <button
                            class="font-semibold text-pillRejectedStatus capitalize text-sm"
                            @click="discardRequest(quote.id)"
                        >
                            Discard Request
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <empty-page
        v-if="(quotes && !quotes.length) || (quotesByVendor && !quotesByVendor.length)"
        :routeName="routeName"
    >
        <template v-slot:message>
            No RFQs found, click on the button below to create an RFQ.
        </template>
        <template v-slot:button-text>
            Create New Request
        </template>
    </empty-page>
</template>

<script>
import { mapState } from 'vuex'
import Request from '@/services/requestHelper'
import moment from 'moment'
import errorFunc from '@/util/error'

export default {
    name: 'quotesHome',
    computed: {
        ...mapState(['user']),
    },
    data() {
        return {
            quotes: null,
            quotesByVendor: null,
            moment,
            startDate: null,
            endDate: null,
            errorMessage: null,
            departments: null,
            showDepartments: false,
            routeName: 'newQuote',
        }
    },
    mounted() {
        if (this.user.data.position.toLowerCase() === 'vendor') {
            this.getRequestsForQuoteByVendor()
        } else {
            this.getAllRequestsForQuote()
        }

        const elements = document.querySelectorAll('.v3dp__datepicker')
        elements[0].childNodes[0].setAttribute('placeholder', '12-06-2019')
        elements[1].childNodes[0].setAttribute('placeholder', '24-08-2021')
    },
    watch: {
        endDate() {
            if (this.startDate) {
                this.filterExpenditureByDate()
            } else {
                this.errorMessage = 'Please select start date.'
            }
        },
        startDate() {
            if (this.endDate) {
                this.filterExpenditureByDate()
            } else {
                this.errorMessage = 'Please select end date.'
            }
        },
    },
    methods: {
        eventDeligate(event) {
            if (!event.target.dataset.hasOwnProperty('filterdepartment')) {
                this.showDepartments = false
            }
        },
        async discardRequest(rfqId) {
            if (confirm('Are you sure you want to decline this request?')) {
                try {
                    const { status } = await Request.postRequest('declined/rfq/request', {
                        rfq_id: rfqId,
                    })

                    if (status >= 200 && status < 300) {
                        const declinedQuote = this.quotesByVendor.find(quote => quote.id === rfqId)
                        const index = this.quotesByVendor.indexOf(declinedQuote)
                        this.quotesByVendor.splice(index, 1)
                    }
                } catch (error) {
                    errorFunc(error.response, this.$router)
                    this.errorMessage = error.response.data.message
                }
            }
        },
        async getAllRequestsForQuote() {
            try {
                const { status, data } = await Request.getRequest('request/quote')
                if (status >= 200 && status < 300) {
                    this.quotes = data.data
                    this.showDepartments = false
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        async getRequestsForQuoteByVendor() {
            try {
                const { data } = await Request.getRequest(
                    `request/quote/by/vendors/${this.user.data.id}`
                )
                this.quotesByVendor = data.data
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        async getAllDepartments() {
            try {
                this.showDepartments = true
                const { status, data } = await Request.getRequest('departments/all')

                if (status === 200) {
                    this.departments = data.data
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        async getExpenditureByDepartment(department) {
            try {
                const { status, data } = await Request.getRequest(
                    `budget/department/${department.toLowerCase()}`
                )

                if (status === 200 && data.data.data.length) {
                    this.expenditureRequests = data.data.data
                    this.showDepartments = false
                } else {
                    this.showDepartments = false
                    this.errorMessage = 'No result for this date range.'
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        async filterExpenditureByDate() {
            try {
                const startDate = this.moment(this.startDate).format('YYYY-MM-DD')
                const endDate = this.moment(this.endDate).format('YYYY-MM-DD')

                const { status, data } = await Request.getRequest(
                    `expenditure/request/date?start_date=${startDate}&end_date=${endDate}`
                )
                if (status === 200) {
                    if (data.data.length) {
                        this.expenditureRequests = data.data
                    } else {
                        this.errorMessage = 'No result for this date range.'
                    }
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>

<style scoped>
.expenditure-items-wrapper > div {
    width: calc(100% / 6);
}
</style>
